import { createApi } from '@reduxjs/toolkit/query/react';
import { MarketSegment } from '@vandebron/shared/interfaces';
import { axiosBaseQuery } from '@vandebron/shared/api/axiosBaseQuery';
import { withOptionalMarketSegment } from '../utils';
import { Contract, ContractInfo, ContractStartDateRange } from './types';

type GetContractsArg = {
  organizationId: string;
  connectionId: string;
  marketSegment: MarketSegment;
};

type GetContractStartDateRangeArg = {
  organizationId: string;
  clusterReference: string;
};

export const contractsApi = createApi({
  reducerPath: 'contracts',
  baseQuery: axiosBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getContracts: builder.query<Contract[], GetContractsArg>({
      query: ({ organizationId, connectionId, marketSegment }) => ({
        url: withOptionalMarketSegment(
          `/consumers/${organizationId}/connections/${connectionId}/contracts`,
          marketSegment
        ),
      }),
    }),
    getContractStartDateRange: builder.query<ContractStartDateRange, GetContractStartDateRangeArg>({
      query: ({ organizationId, clusterReference }) => ({
        url: `/consumers/${organizationId}/startDateRanges?clusterReference=${clusterReference}`,
      }),
    }),
    getAllContracts: builder.query<ContractInfo[], string>({
      query: (organizationId) => ({
        url: `/v1/contracts/${organizationId}`,
      }),
    }),
  }),
});

export const { useGetContractsQuery, useGetContractStartDateRangeQuery, useGetAllContractsQuery } = contractsApi;
